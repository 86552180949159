import React from "react";
import useStore from "../../store/Store";
import HelpDialog from "../HelpDialog";

export default function HelpButton() {
  const { state, dispatch } = useStore();
  const isHelpVisible = state.ui.show_help_dialog;
  const buttonText = isHelpVisible ? "Close" : "Need Help?";

  const handleClick = () => {
    if (isHelpVisible) {
      dispatch({ type: "HIDE_HELP_REQUESTED" });
    } else {
      dispatch({ type: "SHOW_HELP_REQUESTED" });
    }
  };

  return (
    <div className="fixed right-0 bottom-0">
      <HelpDialog />
      <button
        onClick={handleClick}
        className="rounded-full font-semibold text-lg px-6 py-2 mb-4 mr-4
          bg-ab-yellow focus:outline-none focus:shadow-outline-ab-yellow
          transition ease-out duration-200"
      >
        {buttonText}
      </button>
    </div>
  );
}

import React from "react";
import useStore, { onInputChange } from "../../store/Store";
import FormContainer from "../../components/FormContainer";
import FormField from "../../components/FormField";
import { NavButton, BUTTON_TYPE } from "../../components/NavButton";
import { callAPI, ENDPOINTS } from "../../api/API";
import useNavigation, { setPageTitle } from "../../hooks/useNavigation";
import useLocalStorage from "../../hooks/useLocalStorage";
import { FORM_MAIN_IMAGE } from "../../constants";

export default function Organization() {
  const { state, dispatch } = useStore();
  const { is_form_editable } = state.ui;
  const nav = state.ui.navigation;
  const errors = state.session.errors;
  const {
    organization_name,
    organization_website,
    organization_postal,
  } = state.session.data;

  useLocalStorage();
  useNavigation();
  setPageTitle("Where do you work?");

  const onSubmit = (e) => {
    e.preventDefault();
    callAPI(ENDPOINTS.updateOrgInfo, state.session.data, dispatch);
  };

  return (
    <FormContainer
      title="Where do you work?"
      mainImage={FORM_MAIN_IMAGE.location_icon}
      onSubmit={onSubmit}
      isEditable={is_form_editable}
      errors={errors}
    >
      <FormField
        id="organization_name"
        labelText="Organization Name"
        labelFor="organization_name"
        required={true}
      >
        <input
          type="text"
          maxLength="80"
          name="organization_name"
          autoFocus="autoFocus"
          value={organization_name}
          onChange={onInputChange(dispatch)}
        />
      </FormField>

      <FormField
        id="organization_website"
        labelText="Organization Website"
        labelFor="organization_website"
        required={true}
      >
        <input
          name="organization_website"
          type="text"
          value={organization_website}
          maxLength="164"
          onChange={onInputChange(dispatch)}
        />
      </FormField>

      <FormField
        id="organization_postal"
        labelText="Office ZIP Code"
        labelFor="organization_postal"
        required={true}
      >
        <input
          type="text"
          name="organization_postal"
          maxLength="5"
          value={organization_postal}
          onChange={onInputChange(dispatch)}
        />
      </FormField>

      <NavButton text="Next" enabled={nav.is_enabled} isSubmit={true} />

      <NavButton
        text="Back"
        type={BUTTON_TYPE.back}
        enabled={nav.is_enabled}
        onClick={() => dispatch({ type: "ORGANIZATION_BACK" })}
      />
    </FormContainer>
  );
}

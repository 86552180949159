import React from "react";

export default function ProgressIndicator({ isPast, isActive }) {
  const bgColor = isPast || isActive ? "bg-ab-green" : "bg-ab-white";

  return (
    <div
      className={`w-5 h-5 mx-1 rounded-full border-3 border-white ${bgColor}`}
    />
  );
}

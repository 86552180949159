import { navigate } from "@reach/router";
import update from "immutability-helper";

export const UI_STATE_KEY = "ui";

export const uiInitialState = {
  [UI_STATE_KEY]: {
    is_loading: true,
    is_logged_in: false,
    is_form_editable: true,
    show_errors: false,
    show_help_dialog: false,
    sign_up_steps: {
      "/start": { index: 0 },
      "/start/login": { index: 0 },
      "/start/organization": { index: 1 },
      "/start/pricing": { index: 2 },
      "/start/payment": { index: 3 },
      "/start/success": { index: 4 },
      "/start/confirm": { index: 4 },
      "/start/activate/:token": { index: -1 },
    },
    navigation: {
      show_progress: true,
      step_count: 5,
      step_index: 0,
      is_busy: false,
      is_enabled: true,
    },
  },
};

const setBusyState = (state) => {
  return update(state, {
    is_loading: { $set: true },
    is_form_editable: { $set: false },
    navigation: {
      $merge: { is_busy: true, is_enabled: false },
    },
  });
};

const setReadyState = (state) => {
  return update(state, {
    is_loading: { $set: false },
    is_form_editable: { $set: true },
    show_errors: { $set: false },
    navigation: {
      $merge: { is_busy: false, is_enabled: true },
    },
  });
};

export const uiActions = {
  // ----- SESSION / STATE -----

  LOCAL_STATE_LOADED: (state, action) => {
    const new_state = action.state ? action.state[UI_STATE_KEY] : state;
    return setReadyState(new_state);
  },

  LOAD_SESSION_SUCCEEDED: (state, action) => {
    return setReadyState(state);
  },

  LOCATION_CHANGED: (state, action) => {
    const config = state.sign_up_steps[action.path];
    let index = config ? config.index : -1;

    if (index < 0) {
      return update(state, {
        navigation: { $merge: { show_progress: false } },
      });
    }

    return update(state, {
      navigation: {
        $merge: { show_progress: true, step_index: index },
      },
    });
  },

  // ----- HELP / CHAT -----

  SHOW_HELP_REQUESTED: (state, action) => {
    return update(state, {
      show_help_dialog: { $set: true },
    });
  },
  HIDE_HELP_REQUESTED: (state, action) => {
    return update(state, {
      show_help_dialog: { $set: false },
    });
  },

  // ----- REMOTE_API -----
  // Generic handlers for lifecycle events common to all remote API calls.
  // Entity-specific API events can also be handled separately.

  REMOTE_API_REQUESTED: (state, action) => {
    return setBusyState(state);
  },
  REMOTE_API_SUCCEEDED: (state, action) => {
    return setReadyState(state);
  },
  REMOTE_API_FAILED: (state, action) => {
    const new_state = setReadyState(state);
    return update(new_state, {
      show_errors: { $set: true },
    });
  },
  CLIENT_VALIDATION_FAILED: (state, action) => {
    const new_state = setReadyState(state);
    return update(new_state, {
      show_errors: { $set: true },
    });
  },

  // ----- USER -----

  USER_UPDATE_SUCCEEDED: (state, action) => {
    navigate(!!action.data.seeker_key ? "/start/login" : "/start/organization");
    return setReadyState(state);
  },

  // ----- LOGIN -----

  LOGIN_SUCCEEDED: (state, action) => {
    navigate("/start/organization");
    const new_state = setReadyState(state);
    return update(new_state, {
      is_logged_in: { $set: true },
    });
  },
  LOGIN_BACK: (state, action) => {
    navigate(-1);
    return setReadyState(state);
  },
  FORGOT_PASSWORD_REQUESTED: (state, action) => {
    // TODO implement this route:
    // navigate("/start/forgot-password");
    return setReadyState(state);
  },

  // ----- ORGANIZATION -----

  ORGANIZATION_UPDATE_SUCCEEDED: (state, action) => {
    const nextPath = action.data.pricing_plan_predetermined
      ? "/start/payment"
      : "/start/pricing";
    navigate(nextPath);
    return setReadyState(state);
  },
  ORGANIZATION_BACK: (state, action) => {
    navigate(-1);
    return setReadyState(state);
  },

  // ----- PRICING_PLAN -----

  PRICING_PLAN_UPDATE_SUCCEEDED: (state, action) => {
    navigate("/start/payment");
    return setReadyState(state);
  },
  PRICING_PLAN_BACK: (state, action) => {
    navigate(-1);
    return setReadyState(state);
  },
  LOAD_PREDETERMINED_PRICING_PLAN: (state) => {
    return update(state, {
      sign_up_steps: {
        $set: {
          "/start": { index: 0 },
          "/start/login": { index: 0 },
          "/start/organization": { index: 1 },
          "/start/payment": { index: 2 },
          "/start/success": { index: 3 },
          "/start/confirm": { index: 3 },
          "/start/activate/:token": { index: -1 },
        },
      },
      navigation: {
        $merge: {
          step_count: 4,
        },
      },
    });
  },

  // ----- SUBSCRIPTION -----

  SUBSCRIPTION_UPDATE_SUCCEEDED: (state, action) => {
    navigate(state.is_logged_in ? "/start/success" : "/start/confirm");
    return setReadyState(state);
  },
  SUBSCRIPTION_PAYMENT_REQUESTED: (state, action) => {
    return setBusyState(state);
  },
  SUBSCRIPTION_PAYMENT_FAILED: (state, action) => {
    const new_state = setReadyState(state);
    return update(new_state, {
      show_errors: { $set: true },
    });
  },
  SUBSCRIPTION_PAYMENT_CONFIRMED: (state, action) => {
    navigate("/home");

    return update(state, {
      show_errors: { $set: false },
      navigation: {
        $merge: { show_progress: false, is_busy: false, is_enabled: true },
      },
    });
  },
  SUBSCRIPTION_BACK: (state, action) => {
    navigate(-1);
    return setReadyState(state);
  },

  // ----- ACTIVATE_ACCOUNT -----

  ACTIVATE_ACCOUNT_SUCCEEDED: (state, action) => {
    // clearSessionCookie();
    // navigate("/home");
    // return setReadyState(state);
    window.location = `https://${action.data.subdomain}.${process.env.REACT_APP_CUSTOMER_DOMAIN}/home`;
    return state;
  },
  ACTIVATION_TOKEN_RECEIVED: (state, action) => {
    return setReadyState(state);
  },
  ACTIVATION_TOKEN_INVALID: (state, action) => {
    const new_state = setReadyState(state);
    return update(new_state, {
      show_errors: { $set: true },
    });
  },

  HOME_PAGE_REQUESTED: (state, action) => {
    window.location = `https://${action.subdomain}.${process.env.REACT_APP_CUSTOMER_DOMAIN}/home`;
    return state;
  },

  // ----- NAME_SITE -----

  NAME_SITE_BACK: (state, action) => {
    navigate(-1);
    return setReadyState(state);
  },
  NAME_SITE_REQUESTED: (state, action) => {
    return setBusyState(state);
  },
  NAME_SITE_SUCCEEDED: (state, action) => {
    return update(state, {
      is_loading: { $set: false },
      is_form_editable: { $set: false },
      show_errors: { $set: false },
      navigation: {
        $merge: { is_busy: false, is_enabled: false },
      },
    });
  },
};

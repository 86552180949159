import React, { createContext, useContext, useReducer } from "react";
import update from "immutability-helper";
import {
  SESSION_STATE_KEY,
  sessionActions,
  sessionInitialState,
} from "./actions/SessionActions";
import {
  CUSTOMER_SITE_STATE_KEY,
  customerSiteActions,
  customerSiteInitialState,
} from "./actions/CustomerSiteActions";
import { UI_STATE_KEY, uiActions, uiInitialState } from "./actions/UiActions";

const StoreContext = createContext(null);

const initialState = {
  ...sessionInitialState,
  ...customerSiteInitialState,
  ...uiInitialState,
};

const actions = {
  [SESSION_STATE_KEY]: sessionActions,
  [CUSTOMER_SITE_STATE_KEY]: customerSiteActions,
  [UI_STATE_KEY]: uiActions,
};

const reducer = (state, action) => {
  console.log(`Dispatched ${action.type}`);
  let updatedState;

  try {
    Object.keys(state).forEach((key) => {
      const performAction = actions[key][action.type];
      if (performAction) {
        updatedState = updatedState || {};
        updatedState[key] = performAction(state[key], action);
        console.log({ [`Updated ${key} state`]: updatedState });
      }
    });

    if (updatedState) {
      const newState = update(state, { $merge: updatedState });
      console.log({ "Merged global state": newState });
      return newState;
    } else {
      console.log(`No handlers defined, ignoring action: ${action.type}`);
      return state;
    }
  } catch (e) {
    console.error("Failed in reducer:", e);
  }
};

export const initializeState = () => {
  return { ...initialState };
};

function StoreProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState, initializeState);
  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
}

const useStore = () => useContext(StoreContext);

const onInputChange = (dispatch, action) => (element) => {
  const { target = {} } = element;
  const type = target.type || "text";
  const name = target.name;
  let value = "";

  switch (type) {
    case "checkbox":
      value = target.checked;
      break;
    case "select-one":
      value = target.options[target.selectedIndex].value;
      break;
    case "radio":
      value = parseInt(target.value);
      break;
    default:
      value = target.value;
  }

  if (name === "billing_interval") {
    dispatch({ type: "BILLING_INTERVAL_CHANGED", field: { name, value } });
    return;
  }

  dispatch({ type: "FIELD_CHANGED", field: { name, value } });
  if (action) {
    dispatch({ type: action, field: { name, value } });
  }
};

export { StoreContext, StoreProvider, onInputChange };

export default useStore;

import ENDPOINTS from "./Endpoints";
import Validators from "./Validators";
import { logError } from "../utils/logging";

const createRequest = (method, data) => {
  return {
    method,
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(data),
  };
};

const validateRequest = (actionType, data, dispatch) => {
  const validator = Validators[actionType];
  if (validator) {
    const errors = validator(data);
    if (Object.keys(errors).length) {
      dispatch({
        type: "CLIENT_VALIDATION_FAILED",
        name: actionType,
        errors: errors,
      });
      return false;
    }
  }
  return true;
};

const callAPI = (endpoint, data, dispatch, resetValidation = true) => {
  console.log(`Called API: ${endpoint.method} ${endpoint.route}`);
  if (!validateRequest(endpoint.actionType, data, dispatch)) {
    logError();
    return;
  }
  console.log({ "sending data:": data });

  if (resetValidation) {
    dispatch({ type: "RESET_VALIDATION", name: endpoint.actionType });
  }
  dispatch({ type: "REMOTE_API_REQUESTED", name: endpoint.actionType });
  dispatch({ type: `${endpoint.actionType}_REQUESTED` });

  fetch(endpoint.route, createRequest(endpoint.method, data))
    .then((res) => res.json())
    .then((json) => {
      console.log({ "API response": json });
      if (json.errors) {
        dispatch({
          type: "REMOTE_API_FAILED",
          name: endpoint.actionType,
          errors: json.errors,
        });
        dispatch({
          type: `${endpoint.actionType}_FAILED`,
          errors: json.errors,
        });
        logError(`api response from ${endpoint.route} returned errors`);
      } else {
        dispatch({
          type: "REMOTE_API_SUCCEEDED",
          name: endpoint.actionType,
          data: json.data,
        });
        dispatch({ type: `${endpoint.actionType}_SUCCEEDED`, data: json.data });
      }
    })
    .catch((e) => {
      dispatch({
        type: "REMOTE_API_FAILED",
        name: endpoint.actionType,
        errors: { global: [e.toString()] },
      });
      dispatch({
        type: `${endpoint.actionType}_FAILED`,
        errors: { global: [e.toString()] },
      });
      logError(`server error ${endpoint.actionType}`);
    });
};

export { callAPI, ENDPOINTS };

export default callAPI;

// The login service was designed for the AB site, and returns login errors in
// HTML-encoded format, and with hard-coded link paths, e.g.:
//
//     ["We couldn't log you in, please try again!",
//     "Your account will be locked after 5 unsuccessful login attempts;
//     <br><strong>2 attempts left!</strong> You can always
//     <a href='/reset_password'>reset your password</a> if you need to."]
//
// Ideally we will improve this in the service to make it more generic. For now
// we'll just parse out the parts we need and format our own message. This is
// a hack that should be replaced!

const decodeLoginError = (errors) => {
  let attemptsRemaining = -1;
  if (!errors || !errors.forEach) {
    return [
      "An unknown error occurred during login. " +
        "Please try again, or contact us for help.",
    ];
  }
  errors.forEach((err) => {
    if (err.indexOf("Your account will be locked") === 0) {
      const parts = err.split("<strong>");
      if (parts && parts[1]) {
        attemptsRemaining = parts[1].replace(/\D/g, "") || -1;
      }
    } else if (err.indexOf("Sorry! Your account has been locked") === 0) {
      attemptsRemaining = 0;
    }
  });

  if (attemptsRemaining > 0) {
    const s = attemptsRemaining === 1 ? "" : "s";
    return [
      "We couldn't log you in, please try again!",
      "Your account will be locked after 5 unsuccessful login attempts. " +
        "You have " +
        attemptsRemaining +
        " attempt" +
        s +
        " remaining!",
    ];
  } else if (attemptsRemaining === 0) {
    return [
      "Sorry! Your account has been locked after 5 unsuccessful attempts. " +
        "You will be able to try again after 10 minutes.",
    ];
  }
  return errors;
};

export default decodeLoginError;

import React, { useEffect } from "react";
import useStore, { onInputChange } from "../../store/Store";
import FormContainer, { LAYOUTS } from "../../components/FormContainer";
import FormField from "../../components/FormField";
import NavButton from "../../components/NavButton";
import useNavigation, { setPageTitle } from "../../hooks/useNavigation";
import { callAPI, ENDPOINTS } from "../../api/API";
import { FORM_MAIN_IMAGE, PASSWORD_HELP_TEXT } from "../../constants";
import loadSession from "../../util/session";

export default function ActivateAccount({ token = "" }) {
  const { state, dispatch } = useStore();
  const { is_initialized } = state.session;
  const { email, password, password2 } = state.session.data;
  const { activation_token } = state.session;
  const { is_form_editable } = state.ui;
  const nav = state.ui.navigation;
  const errors = state.session.errors;

  useNavigation();
  setPageTitle("Activate your account");

  useEffect(() => {
    if (activation_token === "invalid") {
      return;
    }
    if (!email && !is_initialized) {
      return loadSession(dispatch);
    }
    if (!token) {
      dispatch({ type: "ACTIVATION_TOKEN_INVALID" });
    } else if (activation_token !== token) {
      dispatch({ type: "ACTIVATION_TOKEN_RECEIVED", token: token });
    }
  });

  const disabled = is_form_editable ? "" : "disabled";

  const onSubmit = (e) => {
    e.preventDefault();
    callAPI(
      ENDPOINTS.activateAccount,
      {
        token: activation_token,
        password,
        password2,
      },
      dispatch
    );
  };

  return (
    <FormContainer
      title="Activate your account"
      mainImage={FORM_MAIN_IMAGE.clipboard_icon}
      onSubmit={onSubmit}
      isEditable={is_form_editable}
      layout={LAYOUTS.single}
      errors={errors}
    >
      {/*
      <FormField
        id="email"
        labelText="Email"
        labelFor="email"
        required={true}
        isEditable={false}
      >
        <input
          type="text"
          name="email"
          value={email}
          onChange={onInputChange(dispatch)}
        />
      </FormField>
      */}

      <FormField
        id="password"
        labelText="Password"
        labelFor="password"
        required={true}
        helpText={PASSWORD_HELP_TEXT}
      >
        <input
          type="password"
          name="password"
          disabled={disabled}
          value={password}
          onChange={onInputChange(dispatch)}
        />
      </FormField>

      <FormField
        id="password2"
        labelText="Confirm Password"
        labelFor="password2"
        required={true}
      >
        <input
          type="password"
          name="password2"
          disabled={disabled}
          value={password2}
          onChange={onInputChange(dispatch)}
        />
      </FormField>

      <div className="mx-3 mt-8">
        <p className="text-lg">
          Go to your account to set up your Basic platform!
        </p>
      </div>

      <NavButton text="My Account" enabled={nav.is_enabled} isSubmit={true} />
    </FormContainer>
  );
}


import React from "react";
import Header from "./Header";

export default function Settings() {
  return (
    <div>
      <Header />
      <main className="md:px-20 lg:px-40 xl:px-64">
        <div className="mx-6 lg:mx-auto">
          <h2 className="mt-4">Site Settings</h2>
          <p>
            <br />
            <br />
            <a href="/home">&larr; Home</a>
          </p>
        </div>
      </main>
    </div>
  );
}

const validateLogin = (data) => {
  let { email, password } = data;
  let errors = {};

  email = email.trim();
  password = password.trim();

  if (!email) {
    errors["email"] = ["Email is required."];
  }
  if (!password) {
    errors["password"] = ["Password is required."];
  } else if (password.length < 8) {
    errors["password"] = ["Password is invalid."];
  }
  return errors;
};

const validateUserInfo = (data) => {
  let { first_name, last_name, email } = data;
  let errors = {};

  first_name = first_name.trim();
  last_name = last_name.trim();
  email = email.trim();

  if (!email) {
    errors["email"] = ["Email is required."];
  }
  if (!first_name) {
    errors["first_name"] = ["First name is required."];
  }
  if (!last_name) {
    errors["last_name"] = ["Last name is required."];
  }
  return errors;
};

const Validators = {
  LOGIN: validateLogin,
  USER_UPDATE: validateUserInfo,
};

export default Validators;

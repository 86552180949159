import update from "immutability-helper";
import decodeLoginError from "./loginHelper";
import { PRICING_PLANS } from "../../constants";
import {
  displayTotalPriceWithTXTax,
  displayTXTax,
  numberWithCommas,
  getTax,
  getTotalPrice,
  calculateTXTax,
  calculateTotalWithTXTax,
  getPricingPlanFromState,
  getProductPrice,
} from "../../utils/price";

export const SESSION_STATE_KEY = "session";

const sessionInitialStateData = {
  id: null,
  seeker_key: null,
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  password2: "",
  accepted_individual_terms_date: "",
  organization_name: "",
  organization_website: "https://",
  organization_postal: "",
  subdomain: "",
  pricing_plan: "Basic",
  pricing_plan_predetermined: null,
  billing_first_name: "",
  billing_last_name: "",
  billing_address: "",
  billing_suite: "",
  billing_city: "",
  billing_postal: "",
  billing_state: "",
  billing_tax_exempt: false,
  billing_plan_amount: 450,
  billing_tax_amount: 37.13,
  billing_total_amount: 487.13,
  payment_method_id: "",
  hubspot_contact_id: "",
  billing_interval: 0,
};

function mergeWithDefaultSessionData(defaultData, remote_data) {
  const pricingPlanName =
    remote_data["pricing_plan"] || defaultData["pricing_plan"] || "basic";
  const currentPricingPlan = PRICING_PLANS[pricingPlanName.toLowerCase()];

  const result = Object.keys(defaultData).reduce((acc, key) => {
    let currentData = {
      ...acc,
      [key]: remote_data[key] || defaultData[key],
    };

    // @TODO: This needs to be refactored when we integrate Avalara Tax system
    if (key === "billing_total_amount") {
      currentData = {
        ...currentData,
        [key]: displayTotalPriceWithTXTax(
          currentPricingPlan.prices[defaultData.billing_interval].unit_amount
        ),
      };
    }

    if (key === "billing_tax_amount") {
      currentData = {
        ...currentData,
        [key]: displayTXTax(
          currentPricingPlan.prices[defaultData.billing_interval].unit_amount
        ),
      };
    }

    if (key === "billing_plan_amount") {
      currentData = {
        ...currentData,
        [key]: currentPricingPlan.prices[
          defaultData.billing_interval
        ].unit_amount.toLocaleString(),
      };
    }

    if (key === "billing_tax_exempt") {
      currentData = {
        ...currentData,
        [key]: remote_data["tax_exempt"] || false,
      };
    }
    return currentData;
  }, {});

  return result;
}

export const sessionInitialState = {
  [SESSION_STATE_KEY]: {
    is_initialized: false,
    errors: {},
    data: sessionInitialStateData,
    activation_token: "",
    invoice_url: "",
  },
};

export const sessionActions = {
  LOCAL_STATE_LOADED: (state, action) => {
    let loaded_state = action.state ? action.state[SESSION_STATE_KEY] : state;
    return update(loaded_state, { is_initialized: { $set: true } });
  },

  LOAD_SESSION_SUCCEEDED: (state, action) => {
    if (!action.data) {
      return update(state, { is_initialized: { $set: true } });
    }
    let remote_data = mergeWithDefaultSessionData(
      sessionInitialStateData,
      action.data
    );
    // These are not returned from the server, but they need to exist as keys
    remote_data.password = "";
    remote_data.password2 = "";

    return update(state, {
      is_initialized: { $set: true },
      data: { $set: remote_data },
    });
  },

  FIELD_CHANGED: (state, action) => {
    const { name, value } = action.field;
    if (state.data.hasOwnProperty(name)) {
      return update(state, { data: { $merge: { [name]: value } } });
    }
    return state;
  },

  BILLING_INTERVAL_CHANGED: (state, action) => {
    const { value: billing_interval = 0 } = action.field;
    const pricingPlan = getPricingPlanFromState(state);
    const productPrice = getProductPrice(pricingPlan, billing_interval);
    const tax = getTax(state.data.billing_tax_exempt, productPrice);
    const totalPrice = getTotalPrice(productPrice, tax);
    return update(state, {
      data: {
        $merge: {
          billing_tax_amount: numberWithCommas(tax),
          billing_total_amount: numberWithCommas(totalPrice),
          billing_interval: billing_interval,
        },
      },
    });
  },

  RESET_VALIDATION: (state, action) => {
    return update(state, {
      errors: { $set: {} },
    });
  },

  USER_UPDATE_SUCCEEDED: (state, action) => {
    return update(state, {
      data: {
        $merge: {
          id: action.data.id,
          seeker_key: action.data.seeker_key,
          accepted_individual_terms_date:
            action.data.accepted_individual_terms_date,
          hubspot_contact_id: action.data.hubspot_contact_id,
        },
      },
    });
  },

  LOGIN_SUCCEEDED: (state, action) => {
    // After a successful login, don't keep the password around in state
    return update(state, {
      data: { $merge: { password: "" } },
    });
  },

  LOGIN_FAILED: (state, action) => {
    console.log({ "Error logging in": action.errors });
    const errors = decodeLoginError(action.errors.global);
    return update(state, {
      errors: { $set: { global: errors } },
    });
  },

  LOGIN_BACK: (state, action) => {
    // Reset password if the user leaves the page
    return update(state, {
      data: { $merge: { password: "" } },
      errors: { $set: {} },
    });
  },

  PRICING_PLAN_CHANGED: (state, { pricing_plan }) => {
    return update(state, {
      data: { $merge: { pricing_plan } },
    });
  },

  ORGANIZATION_UPDATE_SUCCEEDED: (state, action) => {
    return update(state, {
      data: { $merge: { subdomain: action.data.subdomain } },
    });
  },

  BILLING_TAX_EXEMPT_CHANGED: (state, action) => {
    const is_exempt = action.field.value;
    const pricePlan =
      state.data.pricing_plan && state.data.pricing_plan.toLowerCase();
    const price =
      PRICING_PLANS[pricePlan || "basic"].prices[state.data.billing_interval]
        .unit_amount;
    const tax_amount = state.data.billing_tax_exempt
      ? 0
      : calculateTXTax(price);
    const total_amount =
      tax_amount === 0
        ? Number(price).toFixed(2)
        : calculateTotalWithTXTax(price);
    return update(state, {
      data: {
        $merge: {
          billing_tax_amount: numberWithCommas(tax_amount),
          billing_total_amount: numberWithCommas(total_amount),
          tax_exempt: is_exempt,
        },
      },
    });
  },

  PAYMENT_METHOD_CREATED: (state, action) => {
    return update(state, {
      data: { $merge: { payment_method_id: action.payment_method_id } },
    });
  },

  SUBSCRIPTION_UPDATE_SUCCEEDED: (state, action) => {
    return update(sessionInitialState[SESSION_STATE_KEY], {
      invoice_url: { $set: action.data.invoice_url },
    });
  },

  CLIENT_VALIDATION_FAILED: (state, action) => {
    console.log({ "Client validation failed": action });
    const errors = action.errors || { global: ["An unknown error occurred."] };
    return update(state, {
      errors: { $set: errors },
    });
  },

  REMOTE_API_FAILED: (state, action) => {
    console.log({ "Remote API failed": action });
    const errors = action.errors || { global: ["An unknown error occurred."] };
    if (errors && errors.global) {
      if (errors.global[0] === "Invalid session") {
        errors.global[0] =
          "It looks like your browser session may have timed out. " +
          "Please refresh the page to restart your session and continue.";
      }
    }
    return update(state, {
      errors: { $set: errors },
    });
  },

  ACTIVATION_TOKEN_RECEIVED: (state, action) => {
    return update(state, {
      activation_token: { $set: action.token },
    });
  },

  ACTIVATION_TOKEN_INVALID: (state, action) => {
    return update(state, {
      activation_token: { $set: "invalid" },
      errors: {
        $set: {
          global: ["The activation token is missing or invalid."],
        },
      },
    });
  },
};

import React from "react";
import useStore from "../../store/Store";
import NavButton, { BUTTON_TYPE } from "../../components/NavButton/NavButton";
import { callAPI, ENDPOINTS } from "../../api/API";
import useNavigation, { setPageTitle } from "../../hooks/useNavigation";
import { PRICING_PLANS, URLS } from "../../constants";
import useLocalStorage from "../../hooks/useLocalStorage";

export default function Pricing() {
  const { state, dispatch } = useStore();
  const { pricing_plan } = state.session.data;
  const nav = state.ui.navigation;

  useLocalStorage();
  useNavigation();
  setPageTitle("Choose your plan");

  const purchaseEnabled = pricing_plan === "Basic";
  const summaryTextColor = purchaseEnabled ? "text-ab-white" : "text-ab-brown";
  const summaryLinkColor = purchaseEnabled ? "text-ab-green" : "text-ab-yellow";
  const summaryBgColor = purchaseEnabled ? "bg-ab-brown" : "bg-ab-cream";
  const summaryOpacity = purchaseEnabled ? "" : "opacity-50";
  const summaryHelpBgColor = purchaseEnabled ? "bg-ab-cream" : "bg-ab-brown";
  const summaryHelpTextColor = purchaseEnabled
    ? "text-ab-brown"
    : "text-ab-white";

  const onSubmit = (e) => {
    e.preventDefault();
    callAPI(ENDPOINTS.updatePricingPlan, state.session.data, dispatch);
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="lg:flex" style={{ textAlign: "center" }}>
        {Object.keys(PRICING_PLANS).map((plan, i) => {
          const isSelected = pricing_plan === PRICING_PLANS[plan].name;
          const opacity = isSelected ? "" : "opacity-50 hover:opacity-75";
          const imgChecked = isSelected ? "checked" : "unchecked";
          if (PRICING_PLANS[plan].hide) return null;
          return (
            <div
              key={i}
              className={`${opacity} mb-8 lg:flex-1 lg:mx-2 self-stretch 
                rounded-lg bg-ab-white shadow-3xl`}
            >
              <a
                href={`#select-${PRICING_PLANS[plan].name.toLowerCase()}-plan`}
                className="font-normal hover:no-underline flex-1 self-stretch"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  dispatch({
                    type: "PRICING_PLAN_CHANGED",
                    pricing_plan: PRICING_PLANS[plan].name,
                  });
                }}
              >
                <header
                  className="border-ab-green border-b-2 bg-ab-cream
                  border-b-4 p-5 pb-3 rounded-t-lg"
                >
                  <img
                    alt=""
                    src={`/static/images/pricing-plan-${imgChecked}.png`}
                    className="absolute h-6"
                  />
                  <h2 className="text-2xl leading-tight">
                    {PRICING_PLANS[plan].name}
                  </h2>
                  <p className="text-3xl font-bold leading-tight -mt-1">
                    <span className="text-ab-green text-xl leading-tight">
                      $
                    </span>
                    {PRICING_PLANS[plan].prices[0].unit_amount.toLocaleString()}
                  </p>
                  <p className="text-lg">
                    per {PRICING_PLANS[plan].prices[0].recurring.interval}
                  </p>
                </header>
                <main>
                  <div className="py-6 px-8">
                    <p className="font-bold">
                      ${PRICING_PLANS[plan].implementationFeeDisplay}
                    </p>
                    <p>
                      One-time implementation
                      <br />
                      &amp; training fee
                    </p>
                  </div>

                  <div
                    className="py-4 px-8 bg-ab-cream border-t-2 border-b-2
                    border-ab-beige"
                  >
                    <p className="font-bold">$0</p>
                    <p>Network setup fee</p>
                  </div>

                  <div className="py-6 px-6">
                    <p className="font-bold">
                      {PRICING_PLANS[plan].featuresTitle}
                    </p>
                    {PRICING_PLANS[plan].features.map((feature, i) => {
                      return (
                        <p key={i} className="yellow-caret">
                          {feature}
                        </p>
                      );
                    })}
                  </div>
                </main>
              </a>
            </div>
          );
        })}

        <div>
          <div
            className={`${summaryBgColor} relative px-16 pt-6 pb-10 rounded-lg 
              mx-2 shadow-3xl`}
            style={purchaseEnabled ? {} : { opacity: 0.9 }}
          >
            <h3
              className={`${summaryTextColor} ${summaryOpacity} text-3xl font-semibold`}
            >
              Basic Plan
            </h3>
            <p className={`${summaryTextColor} ${summaryOpacity} text-xl mb-4`}>
              Billed Monthly
            </p>
            <p
              className={`${summaryTextColor} ${summaryOpacity} text-3xl font-semibold`}
            >
              <span className="text-ab-yellow text-xl leading-tight">$</span>
              450
            </p>
            <h3 className={`${summaryTextColor} ${summaryOpacity} mb-4`}>
              per month
            </h3>
            <h3
              className={`${summaryTextColor} ${summaryOpacity} text-3xl font-semibold`}
            >
              12-Month
            </h3>
            <p className={`${summaryTextColor} ${summaryOpacity} text-xl mb-4`}>
              Subscription
            </p>
            <NavButton
              text="Purchase"
              type={{
                ...BUTTON_TYPE.purchase,
                direction: purchaseEnabled ? "next" : "",
              }}
              enabled={nav.is_enabled && purchaseEnabled}
              isSubmit={true}
            />
          </div>

          <div
            className={`${summaryHelpBgColor} px-16 py-6 rounded-lg mx-2 mt-12 
              bg-ab-brown shadow-3xl`}
          >
            <p className={summaryHelpTextColor}>Want a Professional or</p>
            <p className={summaryHelpTextColor}>Enterprise plan?</p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={URLS.sales_demo_request}
              className={`${summaryLinkColor} underline`}
            >
              Request a demo
            </a>
            .
          </div>
        </div>
      </div>

      <NavButton
        text="Back"
        type={BUTTON_TYPE.purchase_back}
        enabled={nav.is_enabled}
        onClick={() => dispatch({ type: "PRICING_PLAN_BACK" })}
      />
    </form>
  );
}

import React from "react";
import ReactPlayer from "react-player";
import PropTypes from "prop-types";

export default function VideoPlayer({
  url,
  isPlaying = false,
  onPaused = null,
}) {
  return (
    <ReactPlayer
      width="auto"
      style={{ maxHeight: 190 }}
      url={url}
      playing={isPlaying}
      onPause={onPaused}
      config={{
        wistia: {
          options: {
            popover: true,
          },
        },
      }}
    />
  );
}

VideoPlayer.propTypes = {
  url: PropTypes.string,
};

import React from "react";
import useStore, { onInputChange } from "../../store/Store";
import FormContainer, {
  LAYOUTS,
  BODY_STYLES,
} from "../../components/FormContainer";
import FormField from "../../components/FormField/FormField";
import DropDown from "../../components/Forms/DropDown";
import StripeCardElement from "../../components/StripeCardElement";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import NavButton, { BUTTON_TYPE } from "../../components/NavButton/NavButton";
import { callAPI, ENDPOINTS } from "../../api/API";
import useNavigation, { setPageTitle } from "../../hooks/useNavigation";
import { PRICING_PLANS, US_STATES } from "../../constants";
import useLocalStorage from "../../hooks/useLocalStorage";
import { logButtonClick } from "../../utils/logging";

export const isEducationPromo = (plan) => plan === "educationpromo";

export const getPlanName = (plan) =>
  isEducationPromo(plan)
    ? `${PRICING_PLANS[plan].name} *`
    : `${PRICING_PLANS[plan].name} Plan *`;

export const getChargingCadenceMessage = (
  plan,
  totalPrice,
  billing_interval = 0
) =>
  isEducationPromo(plan)
    ? `* After your 3 month free trial ends, your card will automatically be charged ${totalPrice}.`
    : `* Your card will automatically be charged $${totalPrice} per ${
        billing_interval === 0 ? "month" : "year"
      }.`;

export default function Payment() {
  const stripe = useStripe();
  const elements = useElements();
  const { state, dispatch } = useStore();
  const { is_form_editable } = state.ui;
  const nav = state.ui.navigation;
  const errors = state.session.errors;
  const {
    email,
    pricing_plan,
    billing_first_name,
    billing_last_name,
    billing_address,
    billing_suite,
    billing_city,
    billing_postal,
    billing_state,
    billing_tax_exempt,
    billing_tax_amount,
    billing_total_amount,
    billing_interval,
  } = state.session.data;
  const plan = pricing_plan.toLowerCase();

  useLocalStorage();
  useNavigation();
  setPageTitle("Billing and payment");

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded
      return;
    }
    dispatch({ type: "SUBSCRIPTION_PAYMENT_REQUESTED" });

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        email: email,
      },
    });

    if (result.error) {
      dispatch({ type: "SUBSCRIPTION_PAYMENT_FAILED", error: result.error });
    } else {
      let data = state.session.data;
      data["payment_method_id"] = result.paymentMethod.id;
      callAPI(ENDPOINTS.updateSubscription, data, dispatch);
    }
  };

  const handleTermsAndConditionsClick = () =>
    logButtonClick("terms and conditions");

  const fieldWidth = "xl:w-1/2";

  function renderPaymentSummary() {
    return (
      <FormContainer
        title="Purchase Summary"
        className="self-start lg:mr-4 mt-16 lg:mt-0 flex-1 lg:max-w-sm"
        isEditable={is_form_editable}
        layout={LAYOUTS.double}
        bodyStyle={BODY_STYLES.plain}
      >
        <div className="text-lg">
          <div className="flex mt-8 mx-8">
            <div className="flex-1 whitespace-no-wrap">{getPlanName(plan)}</div>
            <div className="flex-1 text-right">
              $
              {PRICING_PLANS[plan].prices[
                billing_interval
              ].unit_amount.toLocaleString()}
            </div>
          </div>
          <div className="flex mx-8 space-x-2 items-center text-sm">
            <input
              type="radio"
              id="monthly-billing"
              checked={billing_interval === 0}
              name="billing_interval"
              value={0}
              onClick={onInputChange(dispatch)}
            />
            <label htmlFor="monthly-billing">Monthly billing</label>
            <input
              type="radio"
              id="annual-billing"
              checked={billing_interval === 1}
              name="billing_interval"
              value={1}
              onClick={onInputChange(dispatch)}
            />
            <label htmlFor="annual-billing">Annual billing</label>
          </div>

          <div className="flex mt-4 mx-8">
            <div className="flex-1">Sales Tax</div>
            <div className="flex-1 text-right">${billing_tax_amount}</div>
          </div>
        </div>

        <div className="mt-4 mx-8 text-sm">
          <label className="cursor-pointer flex" htmlFor="billing_tax_exempt">
            <input
              className="mt-2 mr-3 cursor-pointer"
              type="checkbox"
              id="billing_tax_exempt"
              name="billing_tax_exempt"
              disabled={!nav.is_enabled}
              checked={billing_tax_exempt}
              onChange={onInputChange(dispatch, "BILLING_TAX_EXEMPT_CHANGED")}
            />
            <span className="flex-1">
              Select if you are exempt from sales tax and can provide supporting
              documents.
            </span>
          </label>
        </div>

        <div className="text-xl font-bold flex mt-4 px-8 py-4 bg-ab-cream border-t-2 border-b-2 border-ab-beige">
          <div className="flex-1">Total</div>
          <div className="flex-1 text-right">${billing_total_amount}</div>
        </div>

        <div className="py-6 mx-8 text-sm">
          {getChargingCadenceMessage(
            plan,
            billing_total_amount,
            billing_interval
          )}
        </div>

        <div className="flex absolute mt-4 mx-6">
          <div className="flex-none">
            <img
              src="/static/images/secure-lock.png"
              alt="Secure connection"
              style={{ maxWidth: "50px" }}
            />
          </div>
          <div className="self-center text-sm ml-4">
            This is a secure connection with our payment provider using the TLS
            1.2 protocol.
          </div>
        </div>
      </FormContainer>
    );
  }

  return (
    <div className="relative md:flex flex-wrap mb-20">
      <FormContainer
        title="Billing &amp; Payment Information"
        className="lg:ml-4"
        onSubmit={onSubmit}
        isEditable={is_form_editable}
        layout={LAYOUTS.double}
        errors={errors}
      >
        <FormField
          id="billing_first_name"
          labelText="First Name"
          labelFor="billing_first_name"
          required={true}
          className={fieldWidth}
        >
          <input
            name="billing_first_name"
            value={billing_first_name}
            autoFocus="autoFocus"
            onChange={onInputChange(dispatch)}
            required
          />
        </FormField>

        <FormField
          id="billing_last_name"
          labelText="Last Name"
          labelFor="billing_last_name"
          required={true}
          className={fieldWidth}
        >
          <input
            name="billing_last_name"
            value={billing_last_name}
            onChange={onInputChange(dispatch)}
            required
          />
        </FormField>

        <FormField
          id="billing_address"
          labelText="Street Address"
          labelFor="billing_address"
          required={true}
        >
          <input
            name="billing_address"
            value={billing_address}
            onChange={onInputChange(dispatch)}
            required
          />
        </FormField>

        <FormField
          id="billing_suite"
          labelText="Apt., Unit, Suite"
          labelFor="billing_suite"
          className={fieldWidth}
        >
          <input
            name="billing_suite"
            value={billing_suite}
            onChange={onInputChange(dispatch)}
          />
        </FormField>

        <FormField
          id="billing_city"
          labelText="City"
          labelFor="billing_city"
          required={true}
          className={fieldWidth}
        >
          <input
            name="billing_city"
            value={billing_city}
            onChange={onInputChange(dispatch)}
            required
          />
        </FormField>

        <FormField
          id="billing_state"
          labelText="State"
          labelFor="billing_state"
          required={true}
          className={fieldWidth}
        >
          <DropDown
            type="select"
            name="billing_state"
            onChange={onInputChange(dispatch)}
            placeholder="Select State"
            options={US_STATES}
            required
          />
        </FormField>

        <FormField
          id="billing_postal"
          labelText="Postal Code"
          labelFor="billing_postal"
          required={true}
          className={fieldWidth}
        >
          <input
            name="billing_postal"
            value={billing_postal}
            onChange={onInputChange(dispatch)}
            required
          />
        </FormField>

        <StripeCardElement isEditable={is_form_editable} />

        <div className="mt-6 mx-4">
          <p>
            By purchasing your subscription, you agree to our{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://company.findhelp.com/customerterms/"
              className="focus:outline-none focus:shadow-outline-ab-green rounded"
              onClick={handleTermsAndConditionsClick}
            >
              Customer Terms &amp; Conditions
            </a>
            .
          </p>
        </div>

        <NavButton
          text={nav.is_enabled ? "Pay Now" : "Processing..."}
          type={BUTTON_TYPE.next}
          enabled={nav.is_enabled}
          isSubmit={true}
        />

        <NavButton
          text="Back"
          type={BUTTON_TYPE.back}
          enabled={nav.is_enabled}
          onClick={() => dispatch({ type: "SUBSCRIPTION_BACK" })}
        />
      </FormContainer>

      {renderPaymentSummary()}
    </div>
  );
}

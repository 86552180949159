import { useLocation, navigate } from "@reach/router";
import { useEffect } from "react";
import useStore from "../store/Store";
import {
  getSessionCookie,
  handlePredeterminedPricingPlan,
} from "../util/session";

const setPageTitle = (title) => {
  const title_suffix = title ? ` - ${title}` : "";
  document.title = `findhelp: Get Started${title_suffix}`;
};

const useNavigation = (props) => {
  const { dispatch } = useStore();
  const location = useLocation();

  useEffect(() => {
    if (!getSessionCookie() && location.pathname !== "/start") {
      navigate("/start");
    } else {
      const predetermined_pricing_plan = new URLSearchParams(
        window.location.search
      ).get("tier");
      if (predetermined_pricing_plan)
        handlePredeterminedPricingPlan(dispatch, predetermined_pricing_plan);
      else dispatch({ type: "LOCATION_CHANGED", path: location.pathname });
    }
  }, [dispatch, location]);
};

export { setPageTitle };

export default useNavigation;

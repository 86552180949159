import React, { Children, cloneElement } from "react";

const INPUT_STYLE_DEFAULT =
  " w-full text-lg py-2 px-4 mb-8 outline-none border-b" +
  " focus:border-ab-green transition ease-in-out duration-300";

export default function FormField({
  id,
  labelText,
  labelFor,
  required,
  className,
  helpText,
  isEditable = true,
  errors,
  children,
}) {
  const errorMessages =
    errors &&
    errors[id] &&
    errors[id].map((error, i) => (
      <p key={i} className="text-ab-red ml-4 text-sm">
        {error}
      </p>
    ));
  const borderColor = errorMessages ? " border-ab-red" : " border-ab-yellow";

  const updatedChildren = Children.map(children, (child) => {
    if (
      child.type === "input" ||
      (child.props && child.props.type === "select")
    ) {
      return cloneElement(child, {
        id: id,
        disabled: isEditable === false ? "disabled" : "",
        className:
          (child.props.className || "") + INPUT_STYLE_DEFAULT + borderColor,
      });
    }
    return child;
  });

  return (
    <div className={`form-field px-3 mb-3 w-full ${className || ""}`}>
      <div className="relative">
        <label
          className="caret-ab-yellow font-bold tracking-wide absolute bottom-0"
          htmlFor={labelFor}
        >
          {labelText} {required && <span className="text-ab-yellow">*</span>}
        </label>
        {updatedChildren}
      </div>
      {helpText && (
        <p className="text-ab-brown opacity-50 ml-4 text-sm">{helpText}</p>
      )}
      {errorMessages}
    </div>
  );
}

import React from "react";
import useStore from "../../store/Store";
import ProgressIndicator from "./ProgressIndicator";

export default function ProgressBar() {
  const { state } = useStore();
  const { show_progress, step_count, step_index } = state.ui.navigation;

  return (
    <div
      className={`inline-flex float-right mt-4 ${show_progress || "hidden"}`}
    >
      {[...Array(step_count)].map((step, i) => (
        <ProgressIndicator
          key={i}
          isPast={i < step_index}
          isActive={i === step_index}
        />
      ))}
    </div>
  );
}

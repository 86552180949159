import update from "immutability-helper";

export const CUSTOMER_SITE_STATE_KEY = "customer_site";

export const customerSiteInitialState = {
  [CUSTOMER_SITE_STATE_KEY]: {
    errors: {},
    isProductTourPlaying: false,
    isProductTourWithTranscriptPlaying: false,
    refresh_url: "",
    data: {
      whitelabel_id: "",
      site_name: "",
      subdomain: "",
    },
  },
};

export const customerSiteActions = {
  FIELD_CHANGED: (state, action) => {
    const { name, value } = action.field;
    if (state.data.hasOwnProperty(name)) {
      return update(state, { data: { $merge: { [name]: value } } });
    }
    return state;
  },

  REMOTE_API_FAILED: (state, action) => {
    console.log({ "Remote API failed": action });
    const errors = action.errors || { global: ["An unknown error occurred."] };
    return update(state, {
      errors: { $set: errors },
    });
  },

  CUSTOMER_SITE_SETTINGS_LOADED: (state, action) => {
    console.log({ "Settings loaded": action });
    return update(state, {
      data: { $set: action.data },
    });
  },

  NAME_SITE_SUCCEEDED: (state, action) => {
    const subdomain = action.data.subdomain;
    const host = process.env.REACT_APP_CUSTOMER_DOMAIN;

    return update(state, {
      data: { $set: action.data },
      refresh_url: { $set: `https://${subdomain}.${host}` },
    });
  },

  PRODUCT_TOUR_STARTED: (state, actiono) => {
    return update(state, {
      isProductTourPlaying: { $set: true },
    });
  },

  PRODUCT_TOUR_PAUSED: (state, actiono) => {
    return update(state, {
      isProductTourPlaying: { $set: false },
      isProductTourWithTranscriptPlaying: { $set: false },
    });
  },

  PRODUCT_TOUR_WITH_TRANSCRIPT_STARTED: (state, actiono) => {
    return update(state, {
      isProductTourWithTranscriptPlaying: { $set: true },
    });
  },
};

export const api = {
  hasUsersGroupsAccess: (whitelabelID) =>
    `/internal/api/v1/users_groups/manager/access/${whitelabelID}`,
};

export const BRAND_NAME = "findhelp.org";
export const SESSION_COOKIE_KEY = "abrth_ss";
export const SESSION_STORAGE_KEY = "session_state";
export const PRODUCT_TOUR_VIDEO_URL =
  "https://auntbertha-2.wistia.com/medias/pn2g7cfe6r";
export const PRODUCT_TOUR_VIDEO_WITH_AUDIO_URL =
  "https://auntbertha-2.wistia.com/medias/ran5dto6lr";
export const PRODUCT_TOUR_VIDEO_TRANSCRIPT_URL =
  "https://organizations.findhelp.com/product-tutorial-for-customers-transcript";
export const PASSWORD_HELP_TEXT =
  "Min. 8 characters with 1 capital, 1 lowercase, and 1 special character.";

export const URLS = {
  privacy_policy: "https://company.findhelp.com/privacy/",
  individual_terms: "https://company.findhelp.com/terms/",
  sales_demo_request: "https://company.findhelp.com/our-solutions/sales-demo/",
};

export const FORM_MAIN_IMAGE = {
  no_image: null,
  clipboard_icon: "/static/images/ab-ss-clipboard.png",
  location_icon: "/static/images/ab-ss-location.png",
  email_icon: "/static/images/ab-ss-email.png",
};

export const PRICING_PLANS = {
  basic: {
    prices: [
      {
        recurring: {
          interval: "month",
          interval_count: 1,
        },
        unit_amount: 450,
      },
      {
        recurring: {
          interval: "year",
          interval_count: 1,
        },
        unit_amount: 5400,
      },
    ],
    name: "Basic",
    implementationFee: 0,
    implementationFeeDisplay: "0",
    featuresTitle: "All Free Features Plus:",
    features: [
      "Premium support",
      "Basic reporting suite",
      "Team collaboration",
    ],
    hide: false,
  },
  professional: {
    name: "Professional",
    prices: [
      {
        recurring: {
          interval: "month",
          interval_count: 1,
        },
        unit_amount: 2000,
      },
      {
        recurring: {
          interval: "year",
          interval_count: 1,
        },
        unit_amount: 24000,
      },
    ],
    implementationFee: 3500,
    implementationFeeDisplay: "3,500",
    featuresTitle: "Basic Features Plus:",
    features: [
      "Single Sign On (SSO)",
      "Private domain",
      "Dedicated client and staff sites",
    ],
    hide: false,
  },
  enterprise: {
    name: "Enterprise",
    prices: [
      {
        recurring: {
          interval: "month",
          interval_count: 1,
        },
        unit_amount: 4166,
      },
      {
        recurring: {
          interval: "year",
          interval_count: 1,
        },
        unit_amount: 50000,
      },
    ],
    implementationFee: 8000,
    implementationFeeDisplay: "8,000",
    featuresTitle: "Professional Features Plus:",
    features: [
      "Social determinants screeners, guided search, configurable search results, etc.",
      "API and EHR integration",
      "Dedicated account support",
    ],
    hide: false,
  },
  smhc: {
    name: "Small HC 1-25",
    prices: [
      {
        recurring: {
          interval: "month",
          interval_count: 1,
        },
        unit_amount: 250,
      },
      {
        recurring: {
          interval: "year",
          interval_count: 1,
        },
        unit_amount: 3000,
      },
    ],
    implementationFee: 0,
    implementationFeeDisplay: "0",
    featuresTitle: "",
    features: [],
    hide: true,
  },
  mdhc: {
    name: "Medium HC 26-250",
    prices: [
      {
        recurring: {
          interval: "month",
          interval_count: 1,
        },
        unit_amount: 450,
      },
      {
        recurring: {
          interval: "year",
          interval_count: 1,
        },
        unit_amount: 5400,
      },
    ],
    implementationFee: 0,
    implementationFeeDisplay: "0",
    featuresTitle: "",
    features: [],
    hide: true,
  },
  lghc: {
    name: "Large HC 251+",
    prices: [
      {
        recurring: {
          interval: "month",
          interval_count: 1,
        },
        unit_amount: 1450,
      },
      {
        recurring: {
          interval: "year",
          interval_count: 1,
        },
        unit_amount: 17400,
      },
    ],
    implementationFee: 0,
    implementationFeeDisplay: "0",
    featuresTitle: "",
    features: [],
    hide: true,
  },
  educationpromo: {
    name: "Education Plan - 3 Months Free",
    prices: [
      {
        recurring: {
          interval: "month",
          interval_count: 1,
        },
        unit_amount: 250,
      },
      {
        recurring: {
          interval: "year",
          interval_count: 1,
        },
        unit_amount: 3000,
      },
    ],
    implementationFee: 0,
    implementationFeeDisplay: "0",
    featuresTitle: "",
    features: [],
    hide: true,
  },
};

export const TEXAS_STATE_TAX = 8.25;

// @TODO: Move this to common library later to be used in ab-core and microservices
export const US_STATES = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const READABLE_ROUTE_NAMES = {
  "/start$": "start",
  "/start/login$": "login",
  "/start/organization$": "organization",
  "/start/pricing$": "pricing",
  "/start/payment$": "payment",
  "/start/success$": "payment success",
  "/start/confirm$": "confirmation",
  "/start/activate/.*$": "account activation",
};

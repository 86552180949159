import React from "react";
import FormContainer, { LAYOUTS } from "../../components/FormContainer";
import useStore from "../../store/Store";
import NavButton from "../../components/NavButton";
import useNavigation, { setPageTitle } from "../../hooks/useNavigation";
import { FORM_MAIN_IMAGE } from "../../constants";
import loadSession from "../../util/session";

export default function PostPayment() {
  const { state, dispatch } = useStore();
  const { is_initialized } = state.session;
  const { email, subdomain } = state.session.data;

  if (!email && !is_initialized) {
    loadSession(dispatch);
  }
  useNavigation();
  setPageTitle("Payment success!");

  return (
    <FormContainer
      title="Payment success!"
      layout={LAYOUTS.single}
      mainImage={FORM_MAIN_IMAGE.email_icon}
    >
      <div className="step-inputs-wrapper text-lg px-10 pb-10">
        <p>
          <strong>You’ll receive a receipt shortly at {email}</strong>. In the
          meantime, click the button below to start connecting people to help!
        </p>
        <br />
        <p>
          <strong>Questions?</strong>
          <br />
          Click the help button below or send us a note at{" "}
          <a href="mailto:onboardinghelp@findhelp.com">support@findhelp.com</a>.
        </p>
      </div>

      <NavButton
        text="My Account"
        onClick={() => {
          dispatch({ type: "HOME_PAGE_REQUESTED", subdomain: subdomain });
        }}
      />
    </FormContainer>
  );
}

import React, { useEffect } from "react";
import FormContainer, { LAYOUTS } from "../../components/FormContainer";
import useStore from "../../store/Store";
import useNavigation, { setPageTitle } from "../../hooks/useNavigation";
import { FORM_MAIN_IMAGE } from "../../constants";
import loadSession from "../../util/session";

export default function Confirm() {
  const { state, dispatch } = useStore();
  const { is_initialized } = state.session;
  const { email } = state.session.data;

  useEffect(() => {
    if (!email && !is_initialized) {
      loadSession(dispatch);
    }
  }, [dispatch, email, is_initialized]);

  useNavigation();
  setPageTitle("Payment success!");

  return (
    <FormContainer
      title="Payment success!"
      layout={LAYOUTS.single}
      mainImage={FORM_MAIN_IMAGE.email_icon}
    >
      <div className="step-inputs-wrapper text-lg px-10 pb-6">
        <p>
          <strong>We’ve sent an email to {email}</strong> with a link to
          activate your account. Once your account is active, you can set up
          your platform and start connecting people to help right away!
        </p>
        <br />
        <strong>Questions?</strong>
        <p>
          Click the help button below or send us a note at{" "}
          <a href="mailto:onboardinghelp@findhelp.com">support@findhelp.com</a>.
        </p>
      </div>
    </FormContainer>
  );
}

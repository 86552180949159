import React, { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ProgressBar from "../../components/ProgressBar";
import HelpButton from "../../components/HelpButton";
import { useLocation } from "@reach/router";
import LoadingBar from "../../components/LoadingBar";
import loadSession from "../../util/session";
import useStore from "../../store/Store";
import { logPageView } from "../../utils/logging";

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_API_PUBLISHABLE_KEY}`
);

export default function SignUp({ children }) {
  const location = useLocation();
  const {
    state: {
      ui: { is_loading },
      session: {
        data: { pricing_plan_predetermined },
      },
    },
    dispatch,
  } = useStore();

  // Bit of a hack, but this page is the only exception
  const isPricingPage = location.pathname === "/start/pricing";
  const headerHeight = isPricingPage ? "270px" : "185px";
  const containerTop = isPricingPage ? "-126px" : "-73px";

  let envInfo = "";
  if (process.env.NODE_ENV !== "production") {
    envInfo = (
      <div className="fixed top-0 right-0 bg-ab-cream px-2 text-xs text-ab-grey rounded">
        {process.env.NODE_ENV}
      </div>
    );
  }

  useEffect(() => {
    loadSession(dispatch);
  }, []);

  useEffect(() => {
    if (pricing_plan_predetermined)
      dispatch({ type: "LOAD_PREDETERMINED_PRICING_PLAN" });
  }, [dispatch, pricing_plan_predetermined]);

  useEffect(() => {
    logPageView();
  }, [location.pathname]);

  return (
    <div className={`app-container`}>
      <header
        style={{ height: headerHeight }}
        className="relative bg-ab-beige border-ab-green border-b-4 h-header
          py-6 px-6 md:px-20 lg:px-40 xl:px-64"
      >
        <LoadingBar isLoading={is_loading} />
        <a href="https://company.findhelp.com/" rel="home">
          <img
            src={`${process.env.PUBLIC_URL}/static/images/findhelp/findhelp_stacked_logo.png`}
            alt="findhelp"
            className="inline"
            width="150"
            height="auto"
          />
        </a>
        <ProgressBar />
        {envInfo}
      </header>

      <main className="container mx-auto relative">
        <div style={{ top: containerTop }} className="relative mx-6 lg:mx-auto">
          <Elements stripe={stripePromise}>{children}</Elements>
        </div>
      </main>

      <HelpButton />
    </div>
  );
}

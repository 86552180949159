import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import "./StripeCardElement.css";

let cardOptions = {
  style: {
    base: {
      color: "#351617",
      fontFamily: '"Source Sans Pro", Helvetica, Arial, sans-serif',
      fontSize: "18px",
      fontWeight: "400",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export default function StripeCardElement({ isEditable = true }) {
  if (isEditable === false) {
    cardOptions.disabled = true;
    cardOptions.style.base.color = "rgba(53, 22, 23, 0.6)";
    cardOptions.style.base.backgroundColor = "#f9f8f5";
  }
  return (
    <div className="form-field px-3 mb-3 w-full">
      <div className="relative pb-8">
        <label className="font-bold tracking-wide absolute bottom-0">
          Credit Card Details <span className="text-ab-yellow">*</span>
        </label>
        <CardElement options={cardOptions} />
      </div>
    </div>
  );
}

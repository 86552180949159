import React from "react";
import { Router } from "@reach/router";
import { StoreProvider } from "./store/Store";
import Login from "./pages/SignUp/Login";
import {
  ActivateAccount,
  Confirm,
  Organization,
  PostPayment,
  Payment,
  Pricing,
  SignUp,
  UserInfo,
  Customer,
  Home,
  Settings,
  CreatePlatform,
  NotFound,
} from "./pages";

export default (
  <StoreProvider>
    <Router>
      <SignUp path="start">
        <UserInfo path="/" />
        <Login path="login" />
        <Organization path="organization" />
        <Pricing path="pricing" />
        <Payment path="payment" />
        <PostPayment path="success" />
        <ActivateAccount path="activate" />
        <ActivateAccount path="activate/:token" />
        <Confirm path="confirm" />
        <NotFound noThrow default />
      </SignUp>
      <Customer path="home">
        <Home path="/" />
        <Settings path="/settings" />
        <CreatePlatform path="/name-site" />
        <NotFound noThrow default />
      </Customer>
      <NotFound noThrow default />
    </Router>
  </StoreProvider>
);

import "./NavButton.css";
import React from "react";
import * as PropTypes from "prop-types";
import { logButtonClick } from "../../utils/logging";

export const BUTTON_TYPE = {
  next: {
    direction: "next",
    bgColor: "ab-green",
    textColor: "ab-white",
    styles: { bottom: "-20px", right: "80px" },
    name: "next",
  },
  save: {
    direction: "",
    bgColor: "ab-green",
    textColor: "ab-white",
    styles: { bottom: "-20px", right: "80px" },
    name: "save",
  },
  back: {
    direction: "back",
    bgColor: "ab-yellow",
    textColor: "ab-brown",
    styles: { bottom: "-20px", left: "80px" },
    name: "back",
  },
  purchase: {
    direction: "next",
    bgColor: "ab-green",
    textColor: "ab-white",
    styles: { bottom: "-20px", right: "60px" },
    name: "purchase",
  },
  purchase_back: {
    direction: "back",
    bgColor: "ab-yellow",
    textColor: "ab-brown",
    styles: { bottom: "-70px", left: "20px" },
    name: "back",
  },
};

export default function NavButton({
  text,
  type,
  onClick = () => {},
  isSubmit = false,
  enabled = true,
}) {
  const buttonType = type || BUTTON_TYPE.next;
  const htmlType = isSubmit ? "submit" : "button";

  const handleClick = (e) => {
    logButtonClick(e.target.name);
    onClick();
  };

  return (
    <button
      style={buttonType.styles}
      type={htmlType}
      onClick={handleClick}
      disabled={enabled ? "" : "disabled"}
      name={buttonType.name}
      className={`nav-btn absolute rounded-full bg-${buttonType.bgColor} 
        text-${buttonType.textColor} focus:outline-none 
        focus:shadow-outline-${buttonType.bgColor} ${buttonType.direction}
        transition ease-out duration-200`}
    >
      {text}
    </button>
  );
}

NavButton.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.object,
  onClick: PropTypes.func,
  isSubmit: PropTypes.bool,
  enabled: PropTypes.bool,
};

import React from "react";
import PropTypes from "prop-types";

export default function DropDown({
  name,
  onChange,
  options,
  selected,
  placeholder="",
  ...props
}) {
  const optionNodes = Object.keys(options).map((key) => (
    <option value={key} selected={selected == key}>
      {options[key]}
    </option>
  ));

  let placeholderOption = "";
  if (placeholder) {
    placeholderOption = (
      <option value="" selected={Boolean(selected)}>
        {placeholder}
      </option>
    );
  }

  return (
    <select name={name} onChange={onChange} {...props}>
      {placeholderOption}
      {optionNodes}
    </select>
  );
}

DropDown.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  selected: PropTypes.string,
  placeholder: PropTypes.string,
};

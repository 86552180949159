import React, { useEffect } from "react";
import FormContainer from "../../components/FormContainer";
import FormField from "../../components/FormField";
import useStore, { onInputChange } from "../../store/Store";
import { BUTTON_TYPE, NavButton } from "../../components/NavButton";
import { callAPI, ENDPOINTS } from "../../api/API";

export default function Settings() {
  const { state, dispatch } = useStore();
  const nav = state.ui.navigation;
  const { is_form_editable } = state.ui;
  const { refresh_url } = state.customer_site;
  const { site_name, subdomain } = state.customer_site.data;
  const errors = state.customer_site.errors;

  useEffect(() => {
    if (!window.site_settings) {
      return;
    }
    if (!site_name) {
      const settings = window.site_settings;
      const data = {
        whitelabel_id: settings.id,
        site_name: settings.title,
        subdomain: settings.subdomain_public,
      };
      dispatch({ type: "CUSTOMER_SITE_SETTINGS_LOADED", data: data });
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    callAPI(ENDPOINTS.nameSite, state.customer_site.data, dispatch);
  };

  return (
    <div>
      <header
        style={{ height: "185px" }}
        className="relative bg-ab-beige border-ab-green border-b-4 h-header py-6 px-6 md:px-20 lg:px-40 xl:px-64"
      >
        <a href="https://company.findhelp.com/" rel="home">
          <img
            src={`${process.env.PUBLIC_URL}/static/images/findhelp/findhelp_stacked_logo_png`}
            alt="findhelp"
            className="inline"
            width="150"
            height="auto"
          />
        </a>
      </header>
      <main className="container mx-auto relative">
        <div style={{ top: "-61px" }} className="relative mx-6 lg:mx-auto">
          <FormContainer
            title="My Site Details"
            mainImage={`${process.env.PUBLIC_URL}/static/images/ab-ss-location.png`}
            isEditable={is_form_editable}
            onSubmit={onSubmit}
            errors={errors}
          >
            <FormField
              id="site_name"
              labelText="Name My Site"
              labelFor="site_name"
              required={true}
              errors={errors}
            >
              <input
                type="text"
                name="site_name"
                maxLength="30"
                autoFocus="autoFocus"
                value={site_name}
                onChange={onInputChange(dispatch)}
              />
            </FormField>

            <FormField
              id="subdomain"
              labelText="Set My URL"
              labelFor="subdomain"
              required={true}
              errors={errors}
            >
              <input
                type="text"
                name="subdomain"
                maxLength="22"
                value={subdomain}
                onChange={onInputChange(dispatch)}
              />
              <span className="absolute right-0 text-ab-brown opacity-50 mt-3 mr-1">
                .findhelp.com
              </span>
            </FormField>

            {!refresh_url && (
              <div className="rounded-lg bg-ab-beige p-4 mx-2 mt-6 text-sm">
                <p>
                  Your plan entitles you to a branded site with a custom URL.
                  Please enter a name and subdomain &mdash; you can update both
                  later. Please note that each time you change the URL, your
                  users can no longer access resources on the old URL.
                </p>
              </div>
            )}
            {refresh_url && (
              <div className="rounded-lg bg-ab-beige p-4 mx-2 mt-6">
                <p>Nice job! Your URL was available! Your new site is at:</p>
                <p className="py-2">
                  <a href={refresh_url}>{refresh_url}</a>
                </p>
                <p>
                  Please log into your{" "}
                  <a href={`${refresh_url}/home`}>account dashboard</a> to
                  continue customizing your community resource platform.
                </p>
              </div>
            )}

            <NavButton text="Save" enabled={nav.is_enabled} isSubmit={true} />

            <NavButton
              text="Back"
              type={BUTTON_TYPE.back}
              enabled={nav.is_enabled}
              onClick={() => dispatch({ type: "NAME_SITE_BACK" })}
            />
          </FormContainer>
        </div>
      </main>
    </div>
  );
}

import "./LoadingBar.css";
import React from "react";
import * as PropTypes from "prop-types";

export default function LoadingBar({ isLoading = false }) {
  const indicatorClasses = "absolute bg-ab-teal opacity-100";

  return (
    isLoading && (
      <div className="loading-indicator fixed top-0 left-0 w-full bg-ab-green z-50">
        <span className="background absolute bg-ab-teal w-full opacity-50" />
        <span className={`indicator1 ${indicatorClasses}`} />
        <span className={`indicator2 ${indicatorClasses}`} />
      </div>
    )
  );
}

LoadingBar.propTypes = {
  isLoading: PropTypes.bool,
};

import React, { useEffect } from "react";
import useStore from "../../store/Store";

export default function HelpDialog() {
  const { state, dispatch } = useStore();
  const isHelpVisible = state.ui.show_help_dialog;

  useEffect(() => {
    const handleEsc = (e) => {
      const evt = e || window.event;
      let isEscape = false;
      if ("key" in evt) {
        isEscape = evt.key === "Escape" || evt.key === "Esc";
      } else {
        isEscape = evt.keyCode === 27;
      }
      if (isEscape) {
        dispatch({ type: "HIDE_HELP_REQUESTED" });
      }
    };
    document.addEventListener("keydown", handleEsc);
  }, [dispatch]);

  const closeDialog = () => {
    dispatch({ type: "HIDE_HELP_REQUESTED" });
  };

  const onCloseClick = (e) => {
    e.preventDefault();
    closeDialog();
  };

  const handleEmailClick = (e) => {
    window.open(
      "mailto:onboardinghelp@findhelp.com?subject=I need help " +
        "purchasing my findhelp plan"
    );
    closeDialog();
  };

  const handleContactClick = (e) => {
    window.open("mailto:support@auntbertha.atlassian.net");
    closeDialog();
  };

  const buttonClasses =
    "mx-10 mb-3 border-3 border-ab-teal text-ab-green " +
    "py-2 rounded-full text-lg font-semibold focus:outline-none " +
    "focus:shadow-outline-ab-teal";

  return (
    isHelpVisible && (
      <div>
        <div
          aria-modal="true"
          aria-labelledby="help-dialog"
          role="dialog"
          tabIndex={-1}
          className="bg-white fixed bottom-0 right-0 mb-16 mr-2 w-11/12 md:max-w-sm
        rounded-lg shadow-2xl z-50 overflow-y-auto focus:outline-none
        focus:shadow-outline-ab-green"
        >
          <div>
            <div className="flex justify-between items-center bg-ab-green">
              <h3 id="help-dialog" className="ml-4 my-2 text-ab-white">
                Need Help?
              </h3>
              <a
                href="#close-help"
                aria-label="Close help dialog"
                className="cursor-pointer z-50 px-2 m-2 focus:outline-none text-2xl
                  focus:shadow-outline-ab-white hover:no-underline"
                onClick={onCloseClick}
              >
                <span className="text-ab-white" aria-hidden="true">
                  &times;
                </span>
              </a>
            </div>

            <div className="flex flex-col p-4">
              <button className={buttonClasses} onClick={handleEmailClick}>
                Email us
              </button>
              <button className={buttonClasses} onClick={handleContactClick}>
                Contact us
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

const ENDPOINTS = {
  login: {
    actionType: "LOGIN",
    route: "/api/onboarding/login",
    method: "POST",
  },
  updateUserInfo: {
    actionType: "USER_UPDATE",
    route: "/api/onboarding/user",
    method: "POST",
  },
  updateOrgInfo: {
    actionType: "ORGANIZATION_UPDATE",
    route: "/api/onboarding/organization",
    method: "PUT",
  },
  updatePricingPlan: {
    actionType: "PRICING_PLAN_UPDATE",
    route: "/api/onboarding/plan",
    method: "PUT",
  },
  updateSubscription: {
    actionType: "SUBSCRIPTION_UPDATE",
    route: "/api/onboarding/payment",
    method: "POST",
  },
  activateAccount: {
    actionType: "ACTIVATE_ACCOUNT",
    route: "/api/onboarding/activate",
    method: "POST",
  },
  nameSite: {
    actionType: "NAME_SITE",
    route: "/home/name-site",
    method: "PUT",
  },
};

export default ENDPOINTS;

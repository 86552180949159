import React from "react";

export default function HomeCard({
  title,
  iconFile,
  iconColor,
  onCardClick,
  heightCls = "",
  active = true,
  url = "",
  target = "",
  children,
}) {
  const extraCls = active ? "hover:border-ab-teal" : "";
  return (
    <div className="relative mb-8 w-full xl:w-1/2">
      <div className=" lg:mx-4">
        <div className={`absolute mt-6 rounded-md ${iconColor}`}>
          <img
            className=""
            width="70px"
            alt=""
            src={`${process.env.PUBLIC_URL}/static/images/home/${iconFile}`}
          />
        </div>
        <a
          href={url}
          onClick={onCardClick}
          target={target || ""}
          className="font-normal hover:no-underline block focus:outline-none
            focus:shadow-outline focus:shadow-outline-ab-teal rounded-lg ml-10 z-10"
        >
          <div
            className={`pt-6 pb-10 pl-12 pr-6 rounded-lg shadow-3xl 
              border-1 border-ab-white ${extraCls} ${heightCls}`}
          >
            <h2 className="caret-ab-yellow-lg pb-2">{title}</h2>
            {children}
          </div>
          {!active && (
            <div className="absolute top-0 right-0 mr-2 -mt-1">
              <div
                className="bg-ab-yellow px-4 py-1 rounded-md text-ab-white
                font-semibold text-sm shadow-md"
              >
                Coming soon!
              </div>
            </div>
          )}
        </a>
      </div>
    </div>
  );
}

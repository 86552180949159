import { useEffect } from "react";
import useStore from "../store/Store";
import { SESSION_STORAGE_KEY } from "../constants";

const getLocalData = () => {
  // const dataString = window.localStorage.getItem(SESSION_STORAGE_KEY);
  // try {
  //   return dataString && JSON.parse(dataString);
  // } catch (ex) {
  //   console.log(`Could not restore state from localStorage: ${ex}`);
  // }
  return null;
};

const useLocalStorage = (props) => {
  const { state, dispatch } = useStore();

  useEffect(() => {
    window.addEventListener("load", function (e) {
      const data = getLocalData();
      if (data) {
        window.localStorage.removeItem(SESSION_STORAGE_KEY);
      }
      dispatch({ type: "LOCAL_STATE_LOADED", state: data });
    });

    window.addEventListener("beforeunload", function (e) {
      let data = state;
      data.session.data.password = "";
      data.session.data.password2 = "";
      window.localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(data));
    });
  });
};

export default useLocalStorage;

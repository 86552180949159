import React, { useState, useEffect } from "react";

import { api } from "../../constants";
import Header from "./Header";
import HomeCard from "../../components/HomeCard";
import VideoPlayer from "../../components/VideoPlayer";
import {
  PRODUCT_TOUR_VIDEO_URL,
  PRODUCT_TOUR_VIDEO_WITH_AUDIO_URL,
  PRODUCT_TOUR_VIDEO_TRANSCRIPT_URL,
  BRAND_NAME,
} from "../../constants";
import useStore from "../../store/Store";

export function nameMySiteCard(render) {
  if (render) {
    return (
      <HomeCard
        title="Name my site"
        iconFile="ab-home-site-url.png"
        iconColor="bg-ab-green"
        url="/home/name-site"
        heightCls="xl:h-72"
      >
        <ul className="list-disc list-outside ml-12 mr-4">
          <li>Assign a name and URL for your custom platform</li>
          <li>Update these as often as you need</li>
        </ul>
      </HomeCard>
    );
  } else {
    return null;
  }
}

export function manageUsersAndGroupsCard(render) {
  if (render) {
    return (
      <HomeCard
        title="Manage users and groups"
        iconFile="ab-home-team.png"
        iconColor="bg-ab-teal"
        url="/users"
        heightCls="xl:h-56"
      >
        <ul className="list-disc list-outside ml-12 mr-4">
          <li>Delegate setup to your IT department</li>
          <li>Share programs with team members</li>
          <li>Create folders that your entire team can access</li>
        </ul>
      </HomeCard>
    );
  } else {
    return null;
  }
}

export default function Home() {
  document.title = `Site Tools`;

  const { state, dispatch } = useStore();
  const { isProductTourPlaying, isProductTourWithTranscriptPlaying } =
    state.customer_site;
  const [loggedInUserHasGroupAccess, setLoggedInUserHasGroupAccess] =
    useState(false);

  const onProductTourClick = (e) => {
    e.preventDefault();
    if (e.target.name === "navigate-to-product-tour-transcript")
      window.location.href = PRODUCT_TOUR_VIDEO_TRANSCRIPT_URL;
    const type =
      e.target.name === "play-product-tour-video-with-transcript"
        ? "PRODUCT_TOUR_WITH_TRANSCRIPT_STARTED"
        : "PRODUCT_TOUR_STARTED";
    dispatch({ type });
  };

  const onProductTourPaused = () => {
    dispatch({ type: "PRODUCT_TOUR_PAUSED" });
  };

  const isSelfServe = () => {
    return window?.site_settings?.is_self_serve;
  };

  useEffect(() => {
    async function hasGroupAccess(whitelabelID) {
      const resp = await fetch(api.hasUsersGroupsAccess(whitelabelID));
      if (resp.ok) {
        const { data } = await resp.json();
        setLoggedInUserHasGroupAccess(data?.has_access_to_users_groups);
      } else {
        setLoggedInUserHasGroupAccess(false);
      }
    }
    const whitelabelID = window?.site_settings?.whitelabel_id;
    if (whitelabelID) {
      hasGroupAccess(whitelabelID);
    }
  }, []);

  const VideoActionClassName =
    "hover:underline bg-ab-beige text-xs lg:text-sm text-ab-teal py-1 px-2";

  return (
    <section>
      <Header>
        <div className="mt-16 border-b-2 border-ab-yellow relative">
          <h1
            className="caret-ab-teal-lg text-5xl bg-no-repeat bg-right"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/static/images/header-icon.svg)`,
              backgroundSize: "42px",
            }}
          >
            Site Tools
          </h1>
        </div>
        <p className="my-5 text-lg leading-tight">
          Your search and referral platform, powered by {BRAND_NAME}, will help
          countless people connect to free and reduced-cost services in their
          local communities, with dignity and ease. Continue to set up your
          platform, invite team members, and access account information.
        </p>
      </Header>
      <main className="mb-10 px-6 md:px-20 lg:px-40">
        <div className="flex justify-left flex-wrap mt-20 mx-6 lg:mx-auto">
          <HomeCard
            title="Product tour"
            iconFile="ab-home-tour.png"
            iconColor="bg-ab-yellow"
            onCardClick={onProductTourClick}
            heightCls="xl:h-auto"
          >
            <div className="text-center">
              <VideoPlayer
                url={
                  isProductTourWithTranscriptPlaying
                    ? PRODUCT_TOUR_VIDEO_WITH_AUDIO_URL
                    : PRODUCT_TOUR_VIDEO_URL
                }
                isPlaying={
                  isProductTourWithTranscriptPlaying
                    ? isProductTourWithTranscriptPlaying
                    : isProductTourPlaying
                }
                onPaused={onProductTourPaused}
              />
              <div className={"pt-2 z-20"}>
                <button
                  className={`${VideoActionClassName} m-1`}
                  name="play-product-tour-video-with-transcript"
                >
                  Introduction to Findhelp video with video description
                </button>
                <button
                  className={VideoActionClassName}
                  name="navigate-to-product-tour-transcript"
                >
                  View descriptive transcript
                </button>
              </div>
            </div>
          </HomeCard>

          {nameMySiteCard(isSelfServe())}

          <HomeCard
            title="Customize my site"
            iconFile="ab-home-customize.png"
            iconColor="bg-ab-brown"
            url="/admin-center/customize-my-site"
            heightCls="xl:h-56"
          >
            <ul className="list-disc list-outside ml-12 mr-4">
              <li>Reflect your organization's branding</li>
              <li>Upload your logo</li>
              <li>Use messaging targeted to your population</li>
            </ul>
          </HomeCard>

          {manageUsersAndGroupsCard(loggedInUserHasGroupAccess)}

          <HomeCard
            title="Analytics"
            iconFile="ab-home-analytics.png"
            iconColor="bg-ab-yellow"
            url="/reporting/analytics"
            heightCls="xl:h-56"
          >
            <ul className="list-disc list-outside ml-12 mr-4">
              <li>Compare local program supply against need</li>
              <li>Track your team’s productivity</li>
              <li>Track whether the people you referred got help</li>
            </ul>
          </HomeCard>
        </div>
      </main>

      <footer className="bg-ab-beige pt-10 pb-24 px-6 md:px-20 lg:px-40 xl:px-64">
        <div className="border-b-2 border-ab-yellow relative">
          <h1
            className="caret-ab-teal-lg text-5xl bg-no-repeat bg-right"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/static/images/header-icon.svg)`,
              backgroundSize: "42px",
            }}
          >
            Go to my platform
          </h1>
        </div>
        <p className="my-5 text-lg leading-tight">
          Start connecting people to programs!
        </p>
        <div className="flex justify-end">
          <a
            href="/"
            aria-label="Visit your search platform to start connecting"
            className="nav-btn next rounded-full focus:outline-none
              focus:shadow-outline-ab-green transition ease-out duration-200"
          >
            Start Connecting
          </a>
        </div>
      </footer>
    </section>
  );
}

import Cookies from "js-cookie";
import { navigate } from "@reach/router";
import { SESSION_COOKIE_KEY } from "../constants";

const getSessionCookie = () => {
  return Cookies.get(SESSION_COOKIE_KEY);
};

const clearSessionCookie = () => {
  Cookies.remove(SESSION_COOKIE_KEY);
};

const loadSession = (dispatch) => {
  const cookie = getSessionCookie();
  if (!cookie) {
    return;
  }
  const request = {
    method: "GET",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  fetch(`/api/onboarding/find/cookie:${cookie}`, request)
    .then((res) => res.json())
    .then((json) => {
      console.log({ "Loaded session": json });
      if (json.errors) {
        dispatch({ type: "LOAD_SESSION_FAILED", errors: json.errors });
      } else {
        dispatch({ type: "LOAD_SESSION_SUCCEEDED", data: json.data });
      }
    })
    .catch((e) => {
      dispatch({
        type: "LOAD_SESSION_FAILED",
        errors: { global: [e.toString()] },
      });
    });
};

const handlePredeterminedPricingPlan = (dispatch, pricing_plan) => {
  dispatch({
    type: "FIELD_CHANGED",
    field: { name: "pricing_plan_predetermined", value: true },
  });
  dispatch({ type: "PRICING_PLAN_CHANGED", pricing_plan });
  dispatch({ type: "LOCATION_CHANGED", path: "/start" });
  navigate("/start");
};

export { getSessionCookie, handlePredeterminedPricingPlan, clearSessionCookie };

export default loadSession;

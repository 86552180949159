import React from "react";
import { READABLE_ROUTE_NAMES } from "../constants";

export const logActivity = (data) => {
  const request = {
    method: "post",
    body: JSON.stringify(data),
    credentials: "include",
  };

  fetch(process.env.REACT_APP_LOGGING_URL, { ...request })
    .then((res) => console.log(res.status))
    .catch((err) => console.log(err));
};

export const getUnknownRouteMessage = (unknownRoute) =>
  `unknown page ${unknownRoute} requested`;

export const getPageNameFromRoute = (route) =>
  READABLE_ROUTE_NAMES[
    Object.keys(READABLE_ROUTE_NAMES).find((i) => route.match(i))
  ] || getUnknownRouteMessage(route);

export const logPageView = () =>
  logActivity({
    activityType: `funnel view ${getPageNameFromRoute(
      window.location.pathname
    )} page`,
  });

export const logButtonClick = (buttonName) =>
  logActivity({
    activityType: `funnel ${getPageNameFromRoute(
      window.location.pathname
    )} page click ${buttonName}`,
  });

export const logError = (activityData = "") => {
  const page = `${getPageNameFromRoute(window.location.pathname)} page`;
  logActivity({
    activityData: activityData ? activityData : `error at ${page}`,
    activityType: `funnel ${page} submit error`,
  });
};

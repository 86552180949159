import { PRICING_PLANS, TEXAS_STATE_TAX } from "../constants";

export function calculateTXTax(amount) {
  return Number(Number(amount) * (TEXAS_STATE_TAX / 100)).toFixed(2);
}

export function calculateTotalWithTXTax(amount) {
  // Use explicit number here or else this will be string concatenated instead
  return (Number(amount) + Number(calculateTXTax(amount))).toFixed(2);
}

export function numberWithCommas(number = 0) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function displayTotalPriceWithTXTax(amount) {
  return numberWithCommas(calculateTotalWithTXTax(amount));
}

export function displayTXTax(amount) {
  return numberWithCommas(calculateTXTax(amount));
}

export const getPricingPlanFromState = (
  state = { data: { pricing_plan: "basic" } }
) => state.data.pricing_plan.toLowerCase();

export const getProductPrice = (pricingPlan, billingInterval) =>
  PRICING_PLANS[pricingPlan].prices[billingInterval].unit_amount;

export const getTax = (billing_tax_exempt, productPrice) =>
  billing_tax_exempt
    ? 0
    : Number(displayTXTax(productPrice).replace(/,/g, "")).toFixed(2);

export const getTotalPrice = (productPrice, tax) =>
  (Number(productPrice) + Number(tax)).toFixed(2);

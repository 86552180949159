import React, { Children, cloneElement } from "react";
import useStore from "../../store/Store";

export const LAYOUTS = {
  single: "flex-1 max-w-3xl mx-6 lg:mx-auto",
  double: "lg:flex-1 mx-6 lg:mx-2",
};

export const BODY_STYLES = {
  form: "px-6 lg:px-10 pt-8 pb-16 flex flex-wrap",
  plain: "",
};

export default function FormContainer({
  title,
  mainImage,
  className,
  onSubmit,
  errors,
  children,
  isEditable = true,
  layout = LAYOUTS.single,
  bodyStyle = BODY_STYLES.form,
}) {
  const { state } = useStore();
  const show_errors = state.ui.show_errors;
  const errorItems =
    show_errors &&
    errors &&
    errors.global &&
    errors.global.map((error, i) => (
      <li key={i} className="text-ab-red">
        {error}
      </li>
    ));

  const pageErrors = errorItems && (
    <div className="bg-red-100 px-6 py-4 mt-4 mx-4 rounded-md">
      <ul>{errorItems}</ul>
    </div>
  );

  const updatedChildren = Children.map(children, (child) => {
    // FIXME checking for labelText will work since all inputs should have that,
    //  but it's not ideal. Need to figure out a more generic check that this is
    //  a FormField (the React element properties get minified in prod).
    if (child && child.props && child.props.hasOwnProperty("labelText")) {
      let editable = isEditable;
      if (child.props.hasOwnProperty("isEditable")) {
        // Allow the field to override the form default
        editable = child.props.isEditable;
      }
      return cloneElement(child, {
        isEditable: editable,
        errors: show_errors && errors,
      });
    }
    return child;
  });

  return (
    <section
      className={`form-container relative bg-ab-white rounded-lg shadow-3xl ${layout} ${className}`}
    >
      <header className="border-ab-green border-b-2 bg-ab-cream border-b-4 px-6 p-3 rounded-t-lg">
        <h2 className="caret-ab-teal">{title}</h2>
      </header>
      {mainImage && (
        <main>
          {pageErrors}
          <div className={bodyStyle}>
            <div className="w-56 text-center">
              <img alt="" src={mainImage} className="max-w-1" />
            </div>
            <div className="flex-1 ml-6 mr-10">
              <form onSubmit={onSubmit}>{updatedChildren}</form>
            </div>
          </div>
        </main>
      )}
      {!mainImage && (
        <main>
          {pageErrors}
          <form onSubmit={onSubmit}>
            <div className={bodyStyle}>{updatedChildren}</div>
          </form>
        </main>
      )}
    </section>
  );
}

import React from "react";
import useStore, { onInputChange } from "../../store/Store";
import { callAPI, ENDPOINTS } from "../../api/API";
import FormContainer from "../../components/FormContainer";
import FormField from "../../components/FormField";
import { NavButton, BUTTON_TYPE } from "../../components/NavButton";
import useNavigation, { setPageTitle } from "../../hooks/useNavigation";
import useLocalStorage from "../../hooks/useLocalStorage";
import { FORM_MAIN_IMAGE } from "../../constants";

export default function Login() {
  const { state, dispatch } = useStore();
  const { email } = state.session.data;
  const errors = state.session.errors;
  const nav = state.ui.navigation;
  const { is_form_editable } = state.ui;

  useLocalStorage();
  useNavigation();
  setPageTitle("Sign In");

  const onSubmit = (e) => {
    e.preventDefault();
    callAPI(ENDPOINTS.login, state.session.data, dispatch);
  };

  return (
    <FormContainer
      title="Sign In"
      mainImage={FORM_MAIN_IMAGE.clipboard_icon}
      onSubmit={onSubmit}
      isEditable={is_form_editable}
      errors={errors}
    >
      <div className="px-3 mb-6">
        <p>
          We see that you already have an account with findhelp! &nbsp;
          <strong>Please sign in.</strong>
        </p>
      </div>

      <FormField
        id="email"
        labelText="Email"
        labelFor="email"
        required={true}
        isEditable={false}
      >
        <input
          type="text"
          maxLength="35"
          name="email"
          value={email}
          onChange={onInputChange(dispatch)}
        />
      </FormField>

      <FormField
        id="password"
        labelText="Password"
        labelFor="password"
        required={true}
      >
        <input
          type="password"
          name="password"
          autoFocus="autoFocus"
          onChange={onInputChange(dispatch)}
        />
      </FormField>

      {/*
      <div className="px-3 pt-6">
        <p>
          <a
            href="#forgot-password"
            rel="noopener noreferrer"
            onClick={() => dispatch({ type: "FORGOT_PASSWORD_REQUESTED" })}
          >
            Forgot your password?
          </a>
        </p>
      </div>
      */}

      <NavButton
        text="Back"
        type={BUTTON_TYPE.back}
        enabled={nav.is_enabled}
        onClick={() => dispatch({ type: "LOGIN_BACK" })}
      />

      <NavButton text="Submit" enabled={nav.is_enabled} isSubmit={true} />
    </FormContainer>
  );
}

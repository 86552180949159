import React from "react";
import useStore, { onInputChange } from "../../store/Store";
import { callAPI, ENDPOINTS } from "../../api/API";
import FormContainer, { LAYOUTS } from "../../components/FormContainer";
import FormField from "../../components/FormField";
import NavButton from "../../components/NavButton";
import verifyCaptcha from "../../components/ReCaptcha";
import useNavigation from "../../hooks/useNavigation";
import useLocalStorage from "../../hooks/useLocalStorage";
import { URLS, FORM_MAIN_IMAGE } from "../../constants";

export default function UserInfo() {
  const { state, dispatch } = useStore();
  const { first_name, last_name, email } = state.session.data;
  const { is_form_editable } = state.ui;
  const nav = state.ui.navigation;
  const errors = state.session.errors;

  useLocalStorage();
  useNavigation();

  const onSubmit = (e) => {
    e.preventDefault();
    verifyCaptcha("user_info", (token) => {
      const userInfo = {
        ...state.session.data,
        recaptcha_token: token,
      };
      callAPI(ENDPOINTS.updateUserInfo, userInfo, dispatch);
    });
  };

  return (
    <FormContainer
      title="Let's get started"
      mainImage={FORM_MAIN_IMAGE.clipboard_icon}
      onSubmit={onSubmit}
      isEditable={is_form_editable}
      errors={errors}
      layout={LAYOUTS.single}
    >
      <FormField
        id="first_name"
        labelText="First Name"
        labelFor="first_name"
        required={true}
      >
        <input
          type="text"
          maxLength="35"
          autoFocus="autoFocus"
          name="first_name"
          value={first_name}
          onChange={onInputChange(dispatch)}
        />
      </FormField>

      <FormField
        id="last_name"
        labelText="Last Name"
        labelFor="last_name"
        required={true}
      >
        <input
          type="text"
          maxLength="35"
          name="last_name"
          value={last_name}
          onChange={onInputChange(dispatch)}
        />
      </FormField>

      <FormField
        id="email"
        labelText="Work Email"
        labelFor="email"
        required={true}
      >
        <input
          type="text"
          name="email"
          value={email}
          onChange={onInputChange(dispatch)}
        />
      </FormField>

      <div className="px-3 pt-6">
        <p>
          By clicking submit, you agree to our &nbsp;
          <a
            href={URLS.privacy_policy}
            target="_blank"
            rel="noopener noreferrer"
            className="focus:outline-none focus:shadow-outline-ab-green rounded"
          >
            Privacy Policy
          </a>
          .
        </p>
      </div>

      <NavButton text="Submit" isSubmit={true} enabled={nav.is_enabled} />
    </FormContainer>
  );
}

import "./styles/styles.css";
import { render } from "react-dom";
import Router from "./Router";

// if (process.env.NODE_ENV === "production") {
//   const c = window.console || {};
//   c.log = () => {};
// }

render(Router, document.getElementById("root"));

import "./ReCaptcha.css";

export default function verifyCaptcha(action, callback) {
  if (process.env.NODE_ENV !== "production") {
    // The captcha will raise an error on localhost
    return callback("");
  }
  window.grecaptcha.ready(function () {
    window.grecaptcha
      .execute(`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`, {
        action: action,
      })
      .then((token) => {
        callback(token);
      });
  });
}
